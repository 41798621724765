import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import logo from './logo.svg'

import './index.css'

export interface LogoProps {
	useLink?: boolean
}

export const Logo: FunctionComponent<LogoProps> = ({ useLink = true }) =>
	useLink ? (
		<Link to="/" aria-label="Link to home page" className="navbar-logo">
			<h1 className="visually-hidden color-lightest-grey">Circuit</h1>
			<img src={logo} alt="Circuit" />
		</Link>
	) : (
		<div className="navbar-logo">
			<h1 className="visually-hidden color-lightest-grey">Circuit</h1>
			<img src={logo} alt="Circuit" />
		</div>
	)
