export enum ACTIVITY {
	// application activities
	ASSIGNMENT_READ = 'AssignmentRead',
	ASSIGNMENT_READ_IN_ACTIVE_GROUP_ASSIGNMENT = 'AssignmentReadInActiveGroupAssignment',
	ASSIGNMENT_CREATE = 'AssignmentCreate',
	ASSIGNMENT_UPDATE = 'AssignmentUpdate',
	ASSIGNMENT_DELETE = 'AssignmentDelete',
	ASSIGNMENT_USER_ROLE_READ = 'AssignmentUserRoleRead',
	ASSIGNMENT_USER_ROLE_MODIFY = 'AssignmentUserRoleModify',
	ASSIGNMENT_USER_ROLE_DELETE_OWN = 'AssignmentUserRoleDeleteOwn',

	RUBRIC_READ = 'RubricRead',
	RUBRIC_READ_IN_ACTIVE_GROUP_ASSIGNMENT = 'RubricReadInActiveGroupAssignment',
	RUBRIC_CREATE = 'RubricCreate',
	RUBRIC_UPDATE = 'RubricUpdate',
	RUBRIC_DELETE = 'RubricDelete',
	RUBRIC_ATTACH = 'RubricAttach',
	RUBRIC_USER_ROLE_READ = 'RubricUserRoleRead',
	RUBRIC_USER_ROLE_MODIFY = 'RubricUserRoleModify',
	RUBRIC_USER_ROLE_DELETE_OWN = 'RubricUserRoleDeleteOwn',

	GROUP_ASSIGNMENT_READ_ANY = 'GroupAssignmentReadAny',
	GROUP_ASSIGNMENT_READ_ACTIVE = 'GroupAssignmentReadActive',
	GROUP_ASSIGNMENT_CREATE = 'GroupAssignmentCreate',
	GROUP_ASSIGNMENT_UPDATE = 'GroupAssignmentUpdate',
	GROUP_ASSIGNMENT_DELETE = 'GroupAssignmentDelete',
	GROUP_ASSIGNMENT_RESULT_READ_ANY = 'GroupAssignmentResultReadAny',
	GROUP_ASSIGNMENT_RESULT_READ_OWN = 'GroupAssignmentResultReadOwn',
	GROUP_ASSIGNMENT_SCORE_UPDATE = 'GroupAssignmentScoreUpdate',
	GROUP_ASSIGNMENT_JOB_LOG_READ = 'GroupAssignmentJobLogRead',
	GROUP_ASSIGNMENT_SCORE_DISPATCH = 'GroupAssignmentScoreDispatch',
	GROUP_ASSIGNMENT_DISTRIBUTE_DISPATCH = 'GroupAssignmentDistributeDispatch',

	GROUP_ASSIGNMENT_SUBMISSION_READ_ANY = 'GroupAssignmentSubmissionReadAny',
	GROUP_ASSIGNMENT_SUBMISSION_READ = 'GroupAssignmentSubmissionRead',
	GROUP_ASSIGNMENT_SUBMISSION_CREATE = 'GroupAssignmentSubmissionCreate',
	GROUP_ASSIGNMENT_SUBMISSION_UPDATE_ANY = 'GroupAssignmentSubmissionUpdateAny',
	GROUP_ASSIGNMENT_SUBMISSION_UPDATE_OWN = 'GroupAssignmentSubmissionUpdateOwn',

	GROUP_ASSIGNMENT_CALIBRATION_READ_ANY = 'GroupAssignmentCalibrationReadAny',
	GROUP_ASSIGNMENT_CALIBRATION_READ = 'GroupAssignmentCalibrationRead',

	GROUP_ASSIGNMENT_CALIBRATION_EVALUATION_READ_ANY = 'GroupAssignmentCalibrationEvaluationReadAny',
	GROUP_ASSIGNMENT_CALIBRATION_EVALUATION_READ_OWN = 'GroupAssignmentCalibrationEvaluationReadOwn',
	GROUP_ASSIGNMENT_CALIBRATION_EVALUATION_CREATE = 'GroupAssignmentCalibrationEvaluationCreate',
	GROUP_ASSIGNMENT_CALIBRATION_EVALUATION_UPDATE_ANY = 'GroupAssignmentCalibrationEvaluationUpdateAny',

	GROUP_ASSIGNMENT_SUBMISSION_EVALUATION_READ_ANY = 'GroupAssignmentSubmissionEvaluationReadAny',
	GROUP_ASSIGNMENT_SUBMISSION_EVALUATION_UPDATE_ANY = 'GroupAssignmentSubmissionEvaluationUpdateAny',
	GROUP_ASSIGNMENT_SUBMISSION_PEER_EVALUATION_READ = 'GroupAssignmentSubmissionPeerEvaluationRead',
	GROUP_ASSIGNMENT_SUBMISSION_PEER_EVALUATION_CREATE = 'GroupAssignmentSubmissionPeerEvaluationCreate',
	GROUP_ASSIGNMENT_SUBMISSION_PEER_EVALUATION_UPDATE_OWN = 'GroupAssignmentSubmissionPeerEvaluationUpdateOwn',
	GROUP_ASSIGNMENT_SUBMISSION_SUPERIOR_EVALUATION_CREATE = 'GroupAssignmentSubmissionSuperiorEvaluationCreate',
	GROUP_ASSIGNMENT_SUBMISSION_SUPERIOR_EVALUATION_UPDATE_OWN = 'GroupAssignmentSubmissionSuperiorEvaluationUpdateOwn'
}
