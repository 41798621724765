import { Switch, withRouter } from 'react-router-dom'
import { BASE_ACTIVITY, Group, ModelCollection } from 'studiokit-scaffolding-js'
import ActivityRequiredComponent from 'studiokit-scaffolding-js/lib/components/HOC/ActivityRequiredComponent'
import AsyncComponent from 'studiokit-scaffolding-js/lib/components/HOC/AsyncComponent'
import CollectionComponent from 'studiokit-scaffolding-js/lib/components/HOC/CollectionComponent'
import CollectionItemComponent from 'studiokit-scaffolding-js/lib/components/HOC/CollectionItemComponent'
import EntityComponent from 'studiokit-scaffolding-js/lib/components/HOC/EntityComponent'
import ModelErrorRedirectComponent from 'studiokit-scaffolding-js/lib/components/HOC/ModelErrorRedirectComponent'
import SearchPersistorComponent from 'studiokit-scaffolding-js/lib/components/HOC/SearchPersistorComponent'
import UserComponent from 'studiokit-scaffolding-js/lib/components/HOC/UserComponent'
import Route from 'studiokit-scaffolding-js/lib/components/SentryRoute'
import {
	ActivityOptions,
	canPerformActivityGlobally,
	canPerformActivityGloballyOrOnEntity,
	canPerformActivityGloballyOrOnSomeEntities
} from 'studiokit-scaffolding-js/lib/utils/baseActivity'
import { groupsAsAnythingButLearner } from 'studiokit-scaffolding-js/lib/utils/groupRoles'
import CONFIG from '../../configuration'
import { ACTIVITY } from '../../constants/activity'
import { AssignmentWithGroupAssignments, CircuitGroup, Rubric } from '../../types'

//#region Common

const Downtime = AsyncComponent(() => import('../Downtime'))
const Dashboard = UserComponent(AsyncComponent(() => import('../Dashboard')))
const ErrorScreen = AsyncComponent(() => import('studiokit-scaffolding-js/lib/components/Error'))
const NotFound = AsyncComponent(() => import('studiokit-scaffolding-js/lib/components/NotFound'))
const HelpRoutes = AsyncComponent(() => import('./Help'))
const LoginRoutes = AsyncComponent(() => import('./Login'))
const PrivacyPolicy = AsyncComponent(() => import('../PrivacyPolicy'))

//#endregion Common

//#region Admins

const AdminManage = UserComponent(
	ActivityRequiredComponent(
		AsyncComponent(() => import('../Admin/Manage')),
		canPerformActivityGlobally,
		BASE_ACTIVITY.USER_ROLE_READ_ANY
	)
)

//#endregion Admins

//#region Users

const UsersManage = UserComponent(
	ActivityRequiredComponent(
		SearchPersistorComponent(
			CollectionComponent(
				AsyncComponent(() => import('../User/Manage')),
				'search.users'
			),
			'UsersManage',
			true
		),
		canPerformActivityGlobally,
		BASE_ACTIVITY.USER_READ_ANY
	)
)

const User = UserComponent(
	ActivityRequiredComponent(
		CollectionItemComponent(ModelErrorRedirectComponent(AsyncComponent(() => import('../User'))), 'search.users'),
		canPerformActivityGlobally,
		BASE_ACTIVITY.USER_READ_ANY
	)
)

const UserRoles = UserComponent(
	ActivityRequiredComponent(
		CollectionItemComponent(
			ModelErrorRedirectComponent(AsyncComponent(() => import('../User/Roles'))),
			'search.users'
		),
		(_, options) =>
			canPerformActivityGlobally(BASE_ACTIVITY.USER_ROLE_READ_ANY, options) &&
			canPerformActivityGlobally(BASE_ACTIVITY.USER_READ_ANY, options),
		''
	)
)

const UserSettings = UserComponent(
	CollectionComponent(
		AsyncComponent(() => import('../User/Settings')),
		'userSettings'
	)
)

//#endregion Users

//#region Groups

const canAccessGroupManage = (options: ActivityOptions) =>
	(!!options.entities && groupsAsAnythingButLearner(options.entities as ModelCollection<Group>).length > 0) ||
	canPerformActivityGlobally(BASE_ACTIVITY.GROUP_READ, options) ||
	canPerformActivityGlobally(BASE_ACTIVITY.GROUP_CREATE, options)

const GroupsManage = UserComponent(
	ActivityRequiredComponent(
		SearchPersistorComponent(
			CollectionComponent(
				AsyncComponent(() => import('../Group/Manage')),
				'search.groups'
			),
			'GroupManage',
			true
		),
		(_requiredActivity, options) => canAccessGroupManage(options),
		'',
		'groups'
	)
)

const GroupCreate = UserComponent(
	ActivityRequiredComponent(
		CollectionItemComponent(
			AsyncComponent(() => import('../Group/CreateOrEdit')),
			'groups'
		),
		canPerformActivityGlobally,
		BASE_ACTIVITY.GROUP_CREATE
	)
)

const GroupCopy = UserComponent(
	EntityComponent<CircuitGroup, 'group'>(
		AsyncComponent(() => import('../Group/Copy/Wrapper')),
		undefined,
		BASE_ACTIVITY.GROUP_READ,
		'groups',
		'group'
	)
)

const GroupRoutes = UserComponent(
	EntityComponent<CircuitGroup, 'group'>(
		ActivityRequiredComponent(
			AsyncComponent(() => import('./Group')),
			canPerformActivityGloballyOrOnEntity,
			BASE_ACTIVITY.GROUP_READ,
			'group',
			// if individual access is revoked, redirect to the manage route, otherwise homepage
			(options: ActivityOptions) => (canAccessGroupManage(options) ? '/courses' : '/')
		),
		AsyncComponent(() => import('../Group/Header')),
		BASE_ACTIVITY.GROUP_READ,
		'groups',
		'group'
	)
)

//#endregion Groups

//#region Assignments

const AssignmentsManage = UserComponent(
	ActivityRequiredComponent(
		SearchPersistorComponent(
			CollectionComponent(
				AsyncComponent(() => import('../Assignment/Manage')),
				'search.assignments'
			),
			'AssignmentsManage',
			true
		),
		(requiredActivity, options) =>
			canPerformActivityGloballyOrOnSomeEntities(ACTIVITY.ASSIGNMENT_READ, options) ||
			canPerformActivityGlobally(ACTIVITY.ASSIGNMENT_CREATE, options),
		'',
		'assignments'
	)
)

const AssignmentRoutes = UserComponent(
	EntityComponent<AssignmentWithGroupAssignments, 'assignment'>(
		ActivityRequiredComponent(
			AsyncComponent(() => import('./Assignment')),
			canPerformActivityGloballyOrOnEntity,
			ACTIVITY.ASSIGNMENT_READ,
			'assignment',
			// if individual access is revoked, redirect to the manage route
			(_options: ActivityOptions) => '/assignments'
		),
		AsyncComponent(() => import('../Assignment/Header')),
		ACTIVITY.ASSIGNMENT_READ,
		'assignments',
		'assignment'
	)
)

//#endregion Assignments

//#region Rubrics

const RubricManage = UserComponent(
	ActivityRequiredComponent(
		SearchPersistorComponent(
			CollectionComponent(
				AsyncComponent(() => import('../Rubric/Manage')),
				'search.rubrics'
			),
			'RubricManage',
			true
		),
		(requiredActivity, options) =>
			canPerformActivityGloballyOrOnSomeEntities(ACTIVITY.RUBRIC_READ, options) ||
			canPerformActivityGlobally(ACTIVITY.RUBRIC_CREATE, options),
		'',
		'rubrics'
	)
)

const RubricRoutes = UserComponent(
	EntityComponent<Rubric, 'rubric'>(
		ActivityRequiredComponent(
			AsyncComponent(() => import('./Rubric')),
			canPerformActivityGloballyOrOnEntity,
			ACTIVITY.RUBRIC_READ,
			'rubric',
			// if individual access is revoked, redirect to the manage route
			(_options: ActivityOptions) => '/rubrics'
		),
		AsyncComponent(() => import('../Rubric/Header')),
		ACTIVITY.RUBRIC_READ,
		'rubrics',
		'rubric'
	)
)

//#endregion Rubrics

//#region Lti Launch

const LtiLaunch = UserComponent(
	ActivityRequiredComponent(
		AsyncComponent(() => import('../Lti/LaunchPresenter')),
		canPerformActivityGlobally,
		BASE_ACTIVITY.LTI_LAUNCH_READ_OWN
	)
)

//#endregion Lti Launch

const Routes = () =>
	CONFIG.IS_DOWNTIME ? (
		<Switch>
			<Route component={Downtime} />
		</Switch>
	) : (
		<Switch>
			<Route exact path="/" component={Dashboard} />

			<Route exact path="/admins" component={AdminManage} />

			<Route exact path="/users" render={() => <UsersManage disableAutoLoad />} />
			<Route exact path="/users/:userId" component={User} />
			<Route exact path="/users/:userId/roles" component={UserRoles} />

			<Route exact path="/assignments" render={() => <AssignmentsManage disableAutoLoad />} />
			<Route path="/assignments/:assignmentId" component={AssignmentRoutes} />

			<Route exact path="/rubrics" render={() => <RubricManage disableAutoLoad />} />
			<Route path="/rubrics/:rubricId" component={RubricRoutes} />

			<Route exact path="/courses" render={() => <GroupsManage disableAutoLoad />} />
			<Route exact path="/courses/new" component={GroupCreate} />
			<Route exact path="/courses/:groupId/copy" component={GroupCopy} />
			<Route path="/courses/:groupId" component={GroupRoutes} />

			<Route exact path="/lti-launch/:ltiLaunchId" component={LtiLaunch} />

			<Route exact path="/privacy-policy" component={PrivacyPolicy} />
			<Route path="/login" component={LoginRoutes} />
			<Route exact path="/settings" component={UserSettings} />
			<Route path="/help" component={HelpRoutes} />
			<Route exact path="/error" component={ErrorScreen} />
			<Route exact path="/not-found" component={NotFound} />
			<Route component={NotFound} />
		</Switch>
	)

export default withRouter(Routes)
