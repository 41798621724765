import { AppConfiguration, TIER } from 'studiokit-scaffolding-js'
import { version } from '../package.json'

const windowEnv = (window as any)._env_ || {}
const processEnv = process.env || {}
/**
 * Global Configuration values for the app
 *
 * Combines values from the following sources:
 * * Defaults values in this file
 *   * Values that will not change based on tier
 * * Environment Variables prefixed with `REACT_APP_`
 *   * Useful for local development using `.env` files and `env-cmd`
 * * `public/env.js`
 *   * A static file that adds overrides to `windowEnv` and can be modified after build at runtime
 */
const config: AppConfiguration = {
	NODE_ENV: processEnv.NODE_ENV,

	// App Version + Name
	APP_NAME: 'Circuit',
	VERSION: version,
	VERSION_NAME: '"Roc"',

	// Tier and Base URL
	TIER: (windowEnv.REACT_APP_TIER as TIER) || (processEnv.REACT_APP_TIER as TIER) || TIER.LOCAL,
	API_BASE_URL: windowEnv.REACT_APP_API_URL || processEnv.REACT_APP_API_URL || 'http://vm',
	ROOT_DOMAIN: 'peercircuit.org',
	LOCALHOST_SHARD_KEY:
		windowEnv.REACT_APP_LOCALHOST_SHARD_KEY || processEnv.REACT_APP_LOCALHOST_SHARD_KEY || undefined,

	// Custom Storage URL for local development against vm
	STORAGE_URL: windowEnv.REACT_APP_STORAGE_URL || processEnv.REACT_APP_STORAGE_URL || null,

	// Caliper
	CALIPER_EXTENSIONS_NAMESPACE: 'org.peercircuit',
	CALIPER_SENSOR_ID: 'https://peercircuit.org/sensor',
	CALIPER_SENSOR_APP_ID: 'https://peercircuit.org',
	CALIPER_SENSOR_APP_NAME: 'Circuit',
	CALIPER_EVENT_STORE_OPTIONS: {
		protocol: 'https:',
		port: '443"',
		path: '/collector',
		method: 'POST'
	},

	// Google Analytics
	GOOGLE_ANALYTICS_TRACKING_ID:
		windowEnv.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID ||
		processEnv.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID ||
		undefined,

	// Sentry
	SENTRY_DSN: windowEnv.REACT_APP_SENTRY_DSN || processEnv.REACT_APP_SENTRY_DSN || undefined,
	SENTRY_TRACES_SAMPLE_RATE:
		windowEnv.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || processEnv.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
			? Number(windowEnv.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || processEnv.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
			: undefined,
	SENTRY_REPLAYS_SESSION_SAMPLE_RATE:
		windowEnv.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ||
		processEnv.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
			? Number(
					windowEnv.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ||
						processEnv.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
			  )
			: undefined,
	SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE:
		windowEnv.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ||
		processEnv.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
			? Number(
					windowEnv.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ||
						processEnv.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
			  )
			: undefined,

	// Application Insights
	APP_INSIGHTS_KEY:
		windowEnv.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY ||
		processEnv.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY ||
		undefined,

	// OAuth
	CLIENT_ID: windowEnv.REACT_APP_CLIENT_ID || processEnv.REACT_APP_CLIENT_ID || 'web',
	CLIENT_SECRET: windowEnv.REACT_APP_CLIENT_SECRET || processEnv.REACT_APP_CLIENT_SECRET || '****',

	// Downtime
	IS_DOWNTIME: Boolean(windowEnv.REACT_APP_IS_DOWNTIME) || Boolean(processEnv.REACT_APP_IS_DOWNTIME) || false,

	// LMS
	LMS_NAME: 'Brightspace',

	// Gradable (Ex: Assessment or Assignment) UI Language
	GRADABLE_DESCRIPTOR: 'assignment'
}

export default config
